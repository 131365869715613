import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['column']

  initialize () {
    this.columnsValues = this.columnTargets.map((column) => column.innerText)
    this.maxColumnValue = Math.max.apply(null, this.columnsValues)
    this.minColumnValue = Math.min.apply(null, this.columnsValues)
    this.delta = this.maxColumnValue - this.minColumnValue

    this.buildSalaryBar()
  }

  // -----------------------------------------------------------------------------------------------

  buildSalaryBar () {
    this.columnTargets.forEach((column, item) => {
      const differenceBetweenMaxAndCurrent = this.maxColumnValue - this.columnsValues[item]

      if (differenceBetweenMaxAndCurrent == 0) return

      const topSpacer = (differenceBetweenMaxAndCurrent * 100) / this.delta
      column.style.top = `${topSpacer}%`
    })
  }
}
