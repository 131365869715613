import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['counter', 'votes']

  connect () {
    this.counterValue = this._getCounterValue()

    this._addEventsListeners()
  }

  // -----------------------------------------------------------------------------------------------

  _addEventsListeners () {
    this.element.addEventListener('ajax:success', this._handleSuccess.bind(this))
    this.element.addEventListener('ajax:error', this._handleError.bind(this))
  }

  _handleSuccess (event) {
    const [data, status, xhr] = event.detail
    const voteAction = xhr.getResponseHeader('x-vote')
    const votedOn = xhr.getResponseHeader('x-voted')
    this._updateCounter(voteAction, votedOn)
  }

  _handleError (event) {
    this._shakeCounter()
  }

  _getCounterValue () {
    return parseInt(this.counterTarget.innerText) || 0
  }

  _updateCounter (action, voted) {
    if (action == 'like') { this.counterValue += 1 } else { this.counterValue -= 1 }

    this.counterTarget.innerText = this.counterValue
    this._updateCounterStyle(action, voted)
  }

  _updateCounterStyle (action, voted) {
    const currentVotesClassName = this.votesTarget.classList.value
    let nextVotesClassName = 'votes votes_nulled'

    if (this.counterValue > 0) { nextVotesClassName = 'votes votes_liked' }
    if (this.counterValue < 0) { nextVotesClassName = 'votes votes_disliked' }

    if (action == 'like' && voted == 'true') { nextVotesClassName = nextVotesClassName.concat(' votes_current-user-liked') }
    if (action == 'dislike' && voted == 'true') { nextVotesClassName = nextVotesClassName.concat(' votes_current-user-disliked') }

    if (nextVotesClassName == currentVotesClassName) { return }

    this.votesTarget.classList.remove(...currentVotesClassName.split(' '))
    this.votesTarget.classList.add(...nextVotesClassName.split(' '))
  }

  _shakeCounter () {
    this.counterTarget.classList.add('shake-animation')
    setTimeout(() => { this.counterTarget.classList.remove('shake-animation') }, 1000)
  }
}
