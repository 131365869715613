import App from '../lib/app'
import CommentFormController from './comment-form-controller'

export default class extends CommentFormController {
  static targets = ['parentIdField']

  connect () {
    // console.log('reply-comment-form connected')

    App.on('comments:toggle-reply', this._initializeReply.bind(this))
  }

  disconnect () {
    App.off('comments:toggle-reply')
  }

  handleCancelButtonClick () {
    this._hideForm()
  }

  // -----------------------------------------------------------------------------------------------

  _handleFormSuccessResponse (response) {
    this.commentsContainer = document.querySelector(`#commentBranch${this.parentIdValue}`)
    this._appendComment(response)
    this._hideForm()
    setTimeout(() => {
      this._scrollIntoComment(response)
      this._markNewComment(response)
    }, 100)
  }

  _appendComment (response) {
    this.commentsContainer.insertAdjacentHTML('beforeend', response)
  }

  _getCommentNode (response) {
    const commentFragment = new DOMParser().parseFromString(response, 'text/html')
    const commentId = commentFragment.querySelector('.comment').id

    return document.getElementById(commentId)
  }

  _scrollIntoComment (response) {
    try {
      const commentNode = this._getCommentNode(response)
      const topDistance = commentNode.getBoundingClientRect().top + pageYOffset
      const spacerTop = 24
      window.scrollTo({
        top: topDistance - spacerTop,
        left: 0,
        behavior: 'smooth'
      })
    } catch (e) {}
  }

  _markNewComment (response) {
    try {
      const commentNode = this._getCommentNode(response)
      commentNode.classList.add('comment_new')
      setTimeout(() => commentNode.classList.remove('comment_new'), 3000)
    } catch (e) {}
  }

  _initializeReply (payload) {
    this.parentIdValue = payload.parentId
    this.formContainer = payload.formContainer

    this.parentIdFieldTarget.value = this.parentIdValue
    this.formContainer.appendChild(this.form)

    this._showForm()
  }

  _showForm () {
    this.form.classList.remove('d-none')
    this.textFieldTarget.focus()
  }

  _hideForm () {
    this.form.classList.add('d-none')
  }
}
