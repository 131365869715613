// https://swiperjs.com/api/
import Swiper, { Navigation, Pagination } from 'swiper'

Swiper.use([Navigation, Pagination])

const swiper = new Swiper('.carousel', {
  // Infinite Loop On
  loop: true,

  // -------------------------------------------------------------------------------------------
  // Pagination
  // https://swiperjs.com/api/#pagination
  pagination: {
    el: '.carousel__pagination',
    clickable: true
  },

  // -------------------------------------------------------------------------------------------
  // Navigation
  // https://swiperjs.com/api/#navigation
  navigation: {
    nextEl: '.carousel__button-next',
    prevEl: '.carousel__button-prev'
  }
})
