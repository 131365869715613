import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content', 'incut']

  minChildElementsLength = 5
  targetIndex = 3

  connect () {
    if (!this.hasIncutTarget) return

    this._moveIncut()
  }

  _hasOnlyStrongElement ({ childNodes }) {
    return childNodes.length == 1 && childNodes[0].tagName == 'STRONG'
  }

  _filterElements (elements = []) {
    return elements.filter(element => {
      return element.tagName == 'P' && !this._hasOnlyStrongElement(element)
    })
  }

  _getTargetElement () {
    const childElements = this.contentTarget.children
    const paragraphElements = this._filterElements([...childElements])
    const childElementsLength = paragraphElements.length

    if (childElementsLength < this.minChildElementsLength) return null

    return paragraphElements[this.targetIndex]
  }

  _moveIncut () {
    const element = this._getTargetElement()

    if (!element) return

    element.after(this.incutTarget)
  }
}
