import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import { getRecaptchaToken, setRecaptchaToken } from '../lib/recaptcha'

export default class extends Controller {
  static targets = ['submitButton', 'notificationMessage', 'comment', 'selectedText', 'recaptchaInput', 'formContent', 'successFormContent', 'successMessage', 'errorMessage']

  static values = {
    modalId: String
  }

  initialize () {
    this.recaptchaAction = 'send_error_report'

    this.form = this.element
    this.successFormContentTarget.hidden = true

    this._listenCloseModal()
    this._listenOpenModal()
  }

  async handleFormSubmit (event) {
    event.preventDefault()

    this._disableButton()
    this._clearNotificationMessage()

    const token = await getRecaptchaToken(this.recaptchaAction)
    setRecaptchaToken(this.recaptchaInputTarget, token)

    this._sendForm()
  }

  // -----------------------------------------------------------------------------------------------

  _sendForm () {
    const data = new FormData(this.form)
    data.append('error_report[content]', this.selectedTextTarget.innerHTML)

    Rails.ajax({
      url: this.form.action,
      type: 'POST',
      data,
      success: () => {
        this._handleFormSuccessResponse()
      },
      error: () => {
        this._handleFormErrorResponse()
      }
    })
  }

  _handleFormSuccessResponse () {
    const successMessage = this._getSuccessMessage()

    this.notificationMessageTarget.innerHTML = successMessage

    this._showSuccessForm()
    this._enableButton()

    $(this.modalIdValue).trigger('focus')
  }

  _handleFormErrorResponse () {
    const errorMessage = this._getErrorMessage()

    this.notificationMessageTarget.innerHTML = errorMessage

    this._enableButton()

    $(this.modalIdValue).trigger('focus')
  }

  _getSuccessMessage () {
    return this.successMessageTarget.innerHTML
  }

  _getErrorMessage () {
    return this.errorMessageTarget.innerHTML
  }

  _resetFormState () {
    this._enableButton()
    this._showDefaultForm()

    this._clearNotificationMessage()
    this.form.reset()
  }

  _listenCloseModal () {
    $(this.modalIdValue).on('hidden.bs.modal', this._resetFormState.bind(this))
  }

  _listenOpenModal () {
    $(this.modalIdValue).on('shown.bs.modal', () => {
      this.commentTarget.focus()
    })
  }

  _clearNotificationMessage () {
    this.notificationMessageTarget.innerHTML = ''
  }

  _disableButton () {
    this.submitButtonTarget.disabled = true
  }

  _enableButton () {
    this.submitButtonTarget.disabled = false
  }

  _showSuccessForm () {
    this.formContentTarget.hidden = true
    this.successFormContentTarget.hidden = false
  }

  _showDefaultForm () {
    this.formContentTarget.hidden = false
    this.successFormContentTarget.hidden = true
  }
}
