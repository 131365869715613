import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.element.addEventListener('click', this._handleClick.bind(this), false)
  }

  // -----------------------------------------------------------------------------------------------

  _handleClick (event) {
    event.preventDefault()
    const url = this._getUrlFromEvent(event)
    if (url) { this._openWindow(url) }
  }

  _openWindow (url) {
    const width = 640
    const height = 480
    let top = 0
    const left = Math.round(screen.width / 2 - width / 2)

    if (screen.height > height) { top = Math.round(screen.height / 3 - height / 2) }

    const options = `left=${left},top=${top},width=${width},height=${height},
      personalbar=0,toolbar=0,scrollbars=1,resizable=1`

    const win = window.open(url, 'share', options)

    // if (!win) { location.href = url }

    win.focus()
  }

  _getUrlFromEvent (event) {
    try {
      return event.composedPath().filter(el => el.classList && el.classList.contains('share__item'))[0].href
    } catch {}
  }
}
