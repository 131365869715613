import { Controller } from 'stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  initialize () {
    this.currentTime = new Date().getTime()
    this.hiddenPeriodDays = 14

    this.skippedCookieKey = 'subscriptionSkippedAt'
    this.subscribedCookieKey = 'subscriptionCreatedAt'

    this.classNames = {
      hidden: 'subscription-popup_hidden',
      footer: 'footer__nav'
    }

    this._handleShow()
    this._observeIntersectionWithFooter()
  }

  handleClose () {
    this._close()
    this._setSkippedCookie()
    this.intersectionObserver.disconnect()
  }

  // -----------------------------------------------------------------------------------------------

  _handleShow () {
    if (this._isDisabled()) return

    setTimeout(() => this._show(), 1500)
  }

  _show () {
    this.element.classList.remove(this.classNames.hidden)
  }

  _close () {
    this.element.classList.add(this.classNames.hidden)
  }

  // https://developers.google.com/web/updates/2016/04/intersectionobserver
  _observeIntersectionWithFooter () {
    if (this._isDisabled()) return

    const footerEl = document.querySelector('.' + this.classNames.footer)
    this.intersectionObserver = new IntersectionObserver(entries => {
      const { isIntersecting } = entries[0]

      if (isIntersecting) {
        this._close()
      } else {
        this._show()
      }
    })

    // Start observing an footer
    this.intersectionObserver.observe(footerEl)
  }

  _isDisabled () {
    return this._isSubscribed() || !this._isHiddenPeriodOver()
  }

  _isSubscribed () {
    return !!Cookies.get(this.subscribedCookieKey)
  }

  // Calculation to do in milliseconds
  _isHiddenPeriodOver () {
    const hiddenPeriod = this.hiddenPeriodDays * (24 * 60 * 60 * 1000)

    const skippedAt = Cookies.get(this.skippedCookieKey) || 0
    const currentHiddenPeriod = this.currentTime - skippedAt

    return currentHiddenPeriod > hiddenPeriod
  }

  _setSkippedCookie () {
    Cookies.set(this.skippedCookieKey, this.currentTime, { expires: this.hiddenPeriodDays, path: '/;' })
  }
}
