import { Controller } from 'stimulus'

export default class extends Controller {
  classNames = ['rotation-show-1', 'rotation-show-2']

  connect () {
    const randomIndex = this.getRandomIndex()
    const randomClassName = this.classNames[randomIndex]
    this.element.classList.add(randomClassName)
  }

  getRandomIndex () {
    return Math.round(Math.random()) // 0 or 1
  }
}
