class Device {
  constructor () {
    this.options = Object.assign({}, this.defaultOptions())
    this.isTouch = this.isTouchDevice()

    this.addClassNameDeviceTypeToBodyElement()

    if (this.isTouch) {
      this.calculationUnitHeightWindow()

      window.addEventListener('resize', this.calculationUnitHeightWindow)
    }
  }

  isTouchDevice () {
    let isTouchDevice = false

    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
      isTouchDevice = true
    } else {
      isTouchDevice = window.matchMedia(this.options.query).matches
    }

    return isTouchDevice
  }

  addClassNameDeviceTypeToBodyElement () {
    let className = this.options.noTouchClassName

    if (this.isTouch) {
      className = this.options.touchClassName
    }

    this.options.bodyElement.classList.add(className)
  }

  calculationUnitHeightWindow () {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01

    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  // Options --------------------------------------------------------------------------------------

  defaultOptions () {
    return {
      query: ['(', ' -webkit- -moz- -o- -ms- '.split(' ').join('touch-enabled),('), 'heartz', ')'].join(''),
      bodyElement: document.querySelector('body'),
      touchClassName: 'touch',
      noTouchClassName: 'no-touch'
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const device = new Device()
})
