import CommentFormController from './comment-form-controller'

export default class extends CommentFormController {
  connect () {
    this.commentsContainer = document.querySelector('#commentsTree')
  }

  // -----------------------------------------------------------------------------------------------

  _handleFormSuccessResponse (response) {
    this._appendComment(response)
    this._markNewComment(response)
  }

  _appendComment (response) {
    const commentBranchElement = document.createElement('div')
    commentBranchElement.classList.add('comment-branch')
    commentBranchElement.insertAdjacentHTML('beforeend', response)
    this.commentsContainer.appendChild(commentBranchElement)
  }

  _getCommentNode (response) {
    const commentFragment = new DOMParser().parseFromString(response, 'text/html')
    const commentId = commentFragment.querySelector('.comment').id

    return document.getElementById(commentId)
  }

  _markNewComment (response) {
    try {
      const commentNode = this._getCommentNode(response)
      commentNode.classList.add('comment_new')
      setTimeout(() => commentNode.classList.remove('comment_new'), 3000)
    } catch (e) {}
  }
}
