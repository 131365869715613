import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['menu', 'toggler']

  connect () {
    this.options = this.defaultOptions()

    // Save method for remove event
    this.handleClickDocument = this.handleClickDocument.bind(this)
  }

  isShownMenu () {
    return this.menuTarget.classList.contains(this.options.menuShowClassName)
  }

  toggleMenu () {
    window.scrollTo(0, 0)

    if (!this.isShownMenu()) {
      document.addEventListener('click', this.handleClickDocument)
    } else {
      document.removeEventListener('click', this.handleClickDocument)
    }

    this.menuTarget.classList.toggle(this.options.menuShowClassName)
    this.options.bodyElement.classList.toggle(this.options.menuShowClassNameForBody)
  }

  handleClickDocument (e) {
    if (!this.isShownMenu()) return

    // Hide a menu on clicking outside the menu area
    if (!e.target.closest('.' + this.options.menuShowClassName) && e.target != this.togglerTarget) {
      this.menuTarget.classList.remove(this.options.menuShowClassName)
      this.options.bodyElement.classList.remove(this.options.menuShowClassNameForBody)

      document.removeEventListener('click', this.handleClickDocument)
    }
  }

  handleClick () {
    this.toggleMenu()
  }

  // Options --------------------------------------------------------------------------------------

  defaultOptions () {
    return {
      bodyElement: document.querySelector('body'),
      menuShowClassName: 'menu_show',
      menuShowClassNameForBody: 'menu-shown'
    }
  }
}
