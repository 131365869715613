import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['selectedText']

  static values = {
    areas: Array
  }

  initialize () {
    this.currentSelection = document.getSelection()
    this.selection = {}
    this.selectedText = ''
    this.textMaxLength = 300
  }

  handleSelectionChange () {
    // Fix selection bug for ios safari
    // Selected text should not be updated after click on button
    if (!this.currentSelection.isCollapsed) {
      this.selectedText = this.currentSelection.toString()

      // Fix selection bug for ios safari
      // Modify selection should be work after selection event
      this._saveSelection()

      if (this._isValidSelection()) {
        this._createCustomEvent('error-report-selection.changeSelection')
      } else {
        this.handleSelectStart()
      }
    }
  }

  handleSelectStart () {
    this.selectedText = ''

    this._createCustomEvent('error-report-selection.startSelection')
  }

  handleMobileButtonClick () {
    this._extendSelection()
    this._cropSelectedText()
    this._pastText()
  }

  handleKeyDown () {
    if (this._isValidSelection()) {
      this._extendSelection()
      this._cropSelectedText()
      this._pastText()

      this._createCustomEvent('error-report-selection.showPopup')
    }
  }

  _saveSelection () {
    const { anchorNode, anchorOffset, focusNode, focusOffset } = this.currentSelection

    this.selection = {
      startNode: anchorNode,
      startNodeOffset: anchorOffset,
      endNode: focusNode,
      endNodeOffset: focusOffset
    }
  }

  _extendSelection () {
    this._extendSelectionForward()
    this._extendSelectionBackward()
    this._getSelectedText()
  }

  _extendSelectionForward () {
    let { endNode, endNodeOffset } = this.selection

    while (endNodeOffset <= endNode.length) {
      const nextChar = endNode.data[endNodeOffset]

      if (this._isWholeWord(nextChar, endNodeOffset, endNode)) break

      this._isSelectionBackwards()
        ? endNodeOffset--
        : endNodeOffset++
    }

    this.selection.endNodeOffset = endNodeOffset
  }

  _extendSelectionBackward () {
    let { startNode, startNodeOffset } = this.selection

    while (startNodeOffset >= 0) {
      const nextChar = startNode.data[startNodeOffset]

      if (this._isWholeWord(nextChar, startNodeOffset, startNode)) break

      this._isSelectionBackwards()
        ? startNodeOffset++
        : startNodeOffset--
    }

    this.selection.startNodeOffset = startNodeOffset
  }

  _isWholeWord (char, offset, node) {
    const isWhiteSpace = /\s/i.test(char)
    const isStartOfText = offset === 0
    const isEndOfText = offset === node.length

    return isWhiteSpace || isStartOfText || isEndOfText
  }

  _getSelectedText () {
    const { startNode, startNodeOffset, endNode, endNodeOffset } = this.selection

    this.currentSelection.setBaseAndExtent(startNode, startNodeOffset, endNode, endNodeOffset)
    this.selectedText = this.currentSelection.toString()
    this.currentSelection.removeAllRanges()
  }

  // Checks if the selection is right-to-left or left-to-right
  _isSelectionBackwards () {
    const { anchorNode, anchorOffset, focusNode, focusOffset, isCollapsed } = this.currentSelection
    let backwards = false

    if (!isCollapsed) {
      const range = document.createRange()

      range.setStart(anchorNode, anchorOffset)
      range.setEnd(focusNode, focusOffset)

      backwards = range.collapsed
    }

    return backwards
  }

  _pastText () {
    const passedText = this._removeNonBreakingSpaces(this.selectedText).trim()

    this.selectedTextTarget.innerHTML = passedText
  }

  _cropSelectedText () {
    if (this.selectedText.length <= this.textMaxLength) return

    const croppedText = this.selectedText.substring(0, this.textMaxLength)

    for (let idx = croppedText.length; idx > 0; idx--) {
      const nextChar = croppedText[idx - 1]
      const isWhiteSpace = /\s/i.test(nextChar)

      if (isWhiteSpace) {
        this.selectedText = croppedText.substring(0, idx)

        return
      }
    }
  }

  _removeNonBreakingSpaces (text) {
    return text.replace(/\s+/g, ' ')
  }

  _isValidSelection () {
    const isNotEmpty = this.selectedText.trim()

    return Boolean(isNotEmpty && this._isSelectionInArticle())
  }

  _isSelectionInArticle () {
    if (this.currentSelection.isCollapsed) return false

    const { anchorNode, focusNode } = this.currentSelection

    const isInArticle = this.areasValue.some((className) => {
      const isAnchorNodeInArticle = anchorNode.parentElement.closest(className)
      const isFocusNodeInArticle = focusNode.parentElement.closest(className)

      return isAnchorNodeInArticle && isFocusNodeInArticle
    })

    return isInArticle
  }

  _createCustomEvent (name) {
    const customEvent = new CustomEvent(name)

    window.dispatchEvent(customEvent)
  }
}
