import { Controller } from 'stimulus'

export default class extends Controller {
  static classes = ['active', 'showed']

  initialize () {
    this.mobileBreakPoint = 1024
  }

  handleButtonClick () {
    this._createCustomEvent('error-report-button.buttonClick')
    this._hideButton()
  }

  handleChangeSelection () {
    if (window.innerWidth < this.mobileBreakPoint) {
      this._showButton()
    }
  }

  handleStartSelection () {
    this._hideButton()
  }

  _showButton () {
    this.element.classList.add(this.activeClass)
    document.body.classList.add(this.showedClass)
  }

  _hideButton () {
    this.element.classList.remove(this.activeClass)
    document.body.classList.remove(this.showedClass)
  }

  _createCustomEvent (name) {
    const customEvent = new CustomEvent(name)

    window.dispatchEvent(customEvent)
  }
}
