import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    modalId: String
  }

  handleShow () {
    $(this.modalIdValue).modal('show')
  }

  handleKeyDown (event) {
    const isEnterPressed = event.code === 'Enter'
    const isControlKeyPressed = (event.metaKey || event.ctrlKey)

    if (isControlKeyPressed && isEnterPressed) {
      this._createCustomEvent('error-report-popup.keyDown')
    }
  }

  _createCustomEvent (name) {
    const customEvent = new CustomEvent(name)

    window.dispatchEvent(customEvent)
  }
}
