import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import { getRecaptchaToken, setRecaptchaToken } from '../lib/recaptcha'

export default class extends Controller {
  static targets = ['form', 'input', 'submitButton', 'recaptchaInput', 'successMessage', 'errorMessage']
  static values = {
    successPendingMessage: String,
    successDefaultMessage: String
  }

  initialize () {
    this.recaptchaAction = 'subcribe_user'

    this.formSubmitable = false

    this.subscribedCookieKey = 'subscriptionCreatedAt'

    this.successMessages = {
      pending: this.successPendingMessageValue,
      default: this.successDefaultMessageValue
    }

    this.classNames = {
      success: 'subscription-popup_success',
      error: 'subscription-popup_error',
      shakeAnimation: 'shake-animation',
      disabled: 'disabled'
    }

    this._setFormSubmitting()
  }

  async handleSubmit (event) {
    event.preventDefault()

    if (this.formSubmitable) {
      this.formSubmitable = false
      this._setFormSubmitting()

      const token = await getRecaptchaToken(this.recaptchaAction)
      setRecaptchaToken(this.recaptchaInputTarget, token)

      this._sendForm()
    } else {
      this._shakeForm()
    }
  }

  handleTextInput () {
    const isValid = this.inputTarget.checkValidity()

    this.formSubmitable = isValid
    this._setFormSubmitting()
  }

  // -----------------------------------------------------------------------------------------------

  _sendForm () {
    const data = new FormData(this.formTarget)

    Rails.ajax({
      url: this.formTarget.action,
      type: 'POST',
      data,
      success: (response) => {
        this._handleFormSuccessResponse(response.subscription.status)
      },
      error: () => {
        this._handleFormErrorResponse()
      }
    })
  }

  _handleFormSuccessResponse (status) {
    this._setSubscribedCookie()
    this._setSuccessMessage(status)
    this.element.classList.add(this.classNames.success)
  }

  _handleFormErrorResponse () {
    this.element.classList.add(this.classNames.error)
  }

  _setFormSubmitting () {
    if (this.formSubmitable) {
      this.submitButtonTarget.classList.remove(this.classNames.disabled)
    } else {
      this.submitButtonTarget.classList.add(this.classNames.disabled)
    }
  }

  _setSuccessMessage (status) {
    const successMessage = this.successMessages[status] || this.successMessages.default
    this.successMessageTarget.innerHTML = successMessage
  }

  _setSubscribedCookie () {
    const currentTime = new Date().getTime()
    document.cookie = `${this.subscribedCookieKey}=${currentTime}; path=/;`
  }

  // Animation shake form
  _shakeForm () {
    this.formTarget.classList.add(this.classNames.shakeAnimation)
    setTimeout(() => this.formTarget.classList.remove(this.classNames.shakeAnimation), 1000)
  }
}
