import { Controller } from 'stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  static classes = ['hidden']
  static values = {
    // Days
    lifetime: Number,
    name: String
  }

  initialize () {
    if (this._isAccepted()) return

    this._show()
  }

  handleClickButtonClose () {
    this._close()
    this._setCookie(this.nameValue, true, this.lifetimeValue)
  }

  _show () {
    this.element.classList.remove(this.hiddenClass)
  }

  _close () {
    this.element.classList.add(this.hiddenClass)
  }

  _isAccepted () {
    const cookie = Cookies.get(this.nameValue)

    return Boolean(cookie)
  }

  _setCookie (name, value, lifetime) {
    Cookies.set(name, value, { path: '/;', expires: lifetime })
  }
}
