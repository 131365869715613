import { Controller } from 'stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  handleClick (e) {
    e.preventDefault()
    let alternateLocale = document.head.querySelector('meta[name=alternate-locale]').content;
    let alternateUrl = document.head.querySelector(`meta[name="alternate-url:${alternateLocale}"]`);
    let alternateHreflang = document.head.querySelector(`link[hreflang=${alternateLocale}-BY`);
    let alternatePageUrl = "";

    if (alternateUrl) {
      alternatePageUrl = alternateUrl.content;
    } else {
      alternatePageUrl = alternateHreflang.href;
    }

    this._setCookies("locale", alternateLocale);
    window.location = alternatePageUrl
  }

  _setCookies (name, value) {
    let domain = document.head.querySelector('meta[name=host]').content;
    Cookies.set(name, value, { domain: domain, expires: 365 })
  }
}
