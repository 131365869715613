import { load } from 'recaptcha-v3'

/**
 * @desc   Function calls an object method execute that returns a token
 * @param  action: string
 * @return token: string
 */
export const getRecaptchaToken = async (action) => {
  try {
    const siteKey = document.querySelector('meta[name=recaptcha-v3-site-key]').content
    const recaptcha = await load(siteKey)
    const token = await recaptcha.execute(action)

    return token
  } catch (e) {
    console.error(e)
  }
}

/**
 * @desc   Function set value for input
 * @param  input: HTMLElement
 * @param  token: string
 * @return void
 */
export const setRecaptchaToken = (input, token) => {
  try {
    input.value = token
  } catch (e) {
    console.error(e)
  }
}
