import namespaceEmitter from 'namespace-emitter'

class App {
  constructor () {
    this.bus = namespaceEmitter()
  }

  on (event, fn) {
    this.bus.on(event, fn)
  }

  off (event, fn) {
    this.bus.off(event, fn)
  }

  emit (event, payload) {
    this.bus.emit(event, payload)
  }
}

const app = new App()

window.App = app

Object.freeze(app)

export default app
