import App from '../lib/app'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['formContainer']

  toggleForm () {
    this.parentId = this.data.get('parentId')

    App.emit('comments:toggle-reply', {
      formContainer: this.formContainerTarget,
      parentId: this.parentId
    })
  }
}
