import { Controller } from 'stimulus'
import { post } from '@rails/request.js'
import { getRecaptchaToken, setRecaptchaToken } from '../lib/recaptcha'

export default class extends Controller {
  static targets = ['form', 'recaptchaInput']

  initialize () {
    this.recaptchaAction = 'subcribe_user'

    this.classNames = {
      enableValidation: 'subscription-block__form_needs-validation',
      success: 'subscription-block_success',
      error: 'subscription-block_error',
      loading: 'subscription-block_loading',
      disabled: 'disabled'
    }
  }

  async handleSubmit (event) {
    event.preventDefault()

    this._setInit()

    if (this.formTarget.checkValidity()) {
      this._startLoading()
      this._disableValidation()

      const token = await getRecaptchaToken(this.recaptchaAction)
      setRecaptchaToken(this.recaptchaInputTarget, token)

      await this._sendForm()

      this._stopLoading()
    } else {
      this._enableValidation()
    }
  }

  handleClickClose () {
    const event = new CustomEvent('subscription:close')
    window.dispatchEvent(event)
  }

  handleSubscriptionClose () {
    this._hide()
    this._clearUrlParameters()
  }

  // -----------------------------------------------------------------------------------------------

  async _sendForm () {
    const data = new FormData(this.formTarget)
    try {
      const response = await post(this.formTarget.action, { body: data })

      if (response.ok) {
        this._setSuccess()
        this._dispatchSubmitSuccessEvent()
      } else {
        this._setError()
      }
    } catch {
      this._setError()
    }
  }

  _enableValidation () {
    this.formTarget.classList.add(this.classNames.enableValidation)
  }

  _disableValidation () {
    this.formTarget.classList.remove(this.classNames.enableValidation)
  }

  _setInit () {
    this.element.classList.remove(this.classNames.success)
    this.element.classList.remove(this.classNames.error)
  }

  _setSuccess () {
    this.element.classList.add(this.classNames.success)
  }

  _setError () {
    this.element.classList.add(this.classNames.error)
  }

  _startLoading () {
    this.element.classList.add(this.classNames.loading)
  }

  _stopLoading () {
    this.element.classList.remove(this.classNames.loading)
  }

  _hide () {
    this.element.classList.add('d-none')
  }

  _clearUrlParameters () {
    window.history.replaceState(null, null, window.location.pathname)
  }

  _dispatchSubmitSuccessEvent () {
    const event = new CustomEvent('submit:success')
    this.formTarget.dispatchEvent(event)
  }
}
