// Webpacker requires
// https://github.com/rails/webpacker/tree/5-x-stable#usage
// https://babeljs.io/docs/en/babel-preset-env
//
// See babel.config.js -> @babel/preset-env -> useBuiltIns
// This option configures how @babel/preset-env handles polyfills.
// useBuiltIns: 'entry' requires to add these import statements to the entry point of your app:
// import 'core-js/stable'
// import 'regenerator-runtime/runtime'
//
// But we switched to useBuiltIns: 'usage' and we don't need it.

// Rails unobtrusive scripting adapter
import Rails from '@rails/ujs'

// ..
import '../lib/device'
import '../lib/swiper'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/modal'

// Stimulus controllers
import 'controllers'

// Channels
// import 'channels'

// -------------------------------------------------------------------------------------------------

Rails.start()

// document.addEventListener('DOMContentLoaded', function (event) {})
